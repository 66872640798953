import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import * as mutations from "./mutations";
import * as prefMutations from "./prefMutations";
import * as panelMutations from "./panelMutations";
import * as panelActions from "./panelActions";
import * as busActions from "./busActions";
import * as stopActions from "./stopActions";
import * as routeActions from "./routeActions";
import * as pollerActions from "./pollerActions";
import * as getters from "./getters";
import messagePlugin from "./messagePlugin";
import apiPlugin from "./apiPlugin";
import mapPlugin from "./mapPlugin";

import { apiServerBase, mapObj } from "./inits";

export const STOP_IMG = require("../assets/svg/stop6-20px_min.svg");
export const FAV_STOP_IMG = require("../assets/svg/stop6-fav-20px.svg");

const API_BASE_URL = apiServerBase();
const API = apiPlugin(`${API_BASE_URL}/api`);
const { mappi, stopLayer, stopRouteLayer, busLayer, routeLayer } = mapObj();
const persisted = createPersistedState({
  paths: ["prefs"],
});

busLayer.addTo(mappi);
stopRouteLayer.addTo(mappi);
stopLayer.addTo(mappi);
Vue.use(Vuex);

const mobileUser = window.screen.width < 700;
console.log(`mobileUser::${mobileUser}`);
export default new Vuex.Store({
  state: {
    mobileUser,
    URLS: {
      APIROOT: `${API_BASE_URL}/api`,
    },
    ROUTER: {
      visible: true,
      lazyLoaded: true,
      mobile: false,
      mobileView: "",
      corner: "",
      settings: false,
      info: false,
      mappi: false,
      showUpdates: false,
      showGlobalInfoMsg: false,
    },
    messages: {
      globalInfoMsg: "",
      next: 0,
      show: [],
      q: {},
    },
    options: {
      updatesFound: false,
      routingByClick: {
        type: undefined,
        latlng: undefined,
      },
    },
    filters: {
      show: false,
      lines: [],
      stops: {},
    },
    stop: {
      showStop: 0,
      stops: {
        // 0: {
        //   code: '',
        //   name: '',
        //   departures: [],
        //   lines: [],
        // },
      },
      timetables: {},
    },
    bus: {
      showBus: undefined, // tklId string
      showBusRoute: undefined,
      calls: {}, // below key-values
      // nada: {
      //   code: '',
      //   name: '',
      //   onwardStops: [
      //     { name: '', code: '', arrival: '' },
      //   ],
      // },
    },
    prefs: {
      showLocation: true,
      locationAccepted: false,
      showStops: true,
      showOnlyFavStops: false,
      showGhosts: true,
      realPhysics: false,
      showFavOnStartup: true,
      showOnlyFavBusses: false,
      useTwoColorsForBusses: true,
      singleBusColor: "vihreä",
      fav: {
        stop: [],
        stopOrder: [],
        hiddenStops: [],
        stopNames: {},
        busses: [],
        stopFilters: {},
        virtualStops: {},
        // '1': {
        //   stopCodes: ["4500", "5000"],
        //   name: 'EKA',
        //   filters: [],
        //   filtersOn: false,
        // },
      },
      updates: {
        visitCount: 0,
        seenMsgs: [],
      },
      routing: {
        searches: [],
        saved: [],
        routeOnMap: {},
        showRouteOnMap: false,
        favPlaces: {},
      },
    },
    live: {
      ts: 0,
      latestBlob: {
        ts: 0,
        lines: {},
      },
      userMarker: {},
      userLocationFound: false,
      nearStopsLoadedOnce: false,
      userLocation: {
        lat: 0,
        lng: 0,
      },
      AREAS_SELECTED: [],
      AREAS_ALL: [1, 2],
      BUS_MARKERS: {},
      BUSSES_ALL: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "22",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "33",
        "34",
        "35",
        "36",
        "38",
        "40",
        "41",
        "42",
        "45",
        "48",
        "50",
        "52",
        "55",
        "60",
        "64",
        "65",
        "66",
        "67",
        "70",
        "71",
        "73",
        "74",
        "79",
        "80",
        "81",
        "82",
        "84",
        "85",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "301",
        "303",
      ],
      STOPS_ADDED: {},
    },
    lc: {},
    mappi,
    stopLayer,
    stopRouteLayer,
    busLayer,
    routeLayer,
  },
  getters,
  mutations: {
    ...mutations,
    ...prefMutations,
    ...panelMutations,
  },
  actions: {
    ...busActions,
    ...stopActions,
    ...panelActions,
    ...pollerActions,
    ...routeActions,
  },
  plugins: [messagePlugin, API, persisted, mapPlugin],
});
